import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Section } from "../section";
import { ContentWrapper } from "../common";
import { Title2 } from "../Headings";
import { Link } from "gatsby";
import { APARTMENT_SELECTION } from "../../routes";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 60px;
  column-gap: 100px;

  @media (min-width: 992px) {
    grid-template-columns: 1fr 1fr;

    & > div:first-child {
      padding-top: 60px;
    }
  }
`;

const MainText = styled.p`
  font-weight: 500;
  font-size: 1.375rem;
  line-height: 2rem;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
`;

const SmallText = styled.p`
  font-size: 1.125rem;
  line-height: 1.75rem;
`;

const CustomBtn = styled(Link)`
  cursor: pointer;
  font-family: 'Oswald', Georgia, sans-serif;
  text-align: center;
  text-decoration: none;
  width: 100%;
  display: block;
  background-color: ${({theme}) => theme.colors.primary};
  color: ${({theme}) => theme.colors.white};
  padding: 8px;
  font-size: 1.75rem;
  font-weight: 200;
  text-transform: uppercase;
  border: 1px solid ${({theme}) => theme.colors.primary};
  transition: all 0.5s ease;

  &:hover {
    text-decoration: none;
    color: ${({theme}) => theme.colors.primary};
    background-color: transparent;
  }

  @media (min-width: 576px) {
    width: 330px;
  }

  @media (min-width: 992px) {
    margin-bottom: 60px;
  }
`;

const Tile = styled(CustomBtn)`
  width: 90px;
  height: 90px;
  font-size: 1.5rem;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-bottom: 0;

  span:last-child {
    font-family: 'Poppins', Georgia, sans-serif;
    font-size: 0.875rem;
    text-transform: none;
  }

  @media (min-width: 768px) {
    width: 100px;
    height: 100px;
  }

  @media (min-width: 1400px) {
    width: 150px;
    height: 150px;
    font-size: 2rem;

    span:last-child {
      font-size: 1.25rem;
    }
  }
`;

const Tiles = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin: 35px 0;

  @media (min-width: 768px) {
    margin: 70px 0;
    gap: 24px;
  }
`;

function MoreIndexInfo() {
  return (
      <Section>
        <ContentWrapper>
          <Grid>
            <div>
              <Title2>Třešňovku budete mít rádi</Title2>
              <MainText>
                Třešňovka je 4&nbsp;podlažní bytový komplex 26&nbsp;bytových jednotek o&nbsp;různých dispozicích, od menších 1+kk až po prostorné 3+kk. Dokončení plánujeme na 3Q&nbsp;2025.
              </MainText>
              <SmallText>
                Byty jsme navrhli ve velmi kvalitním provedení a&nbsp;v&nbsp;moderním vzdušném designu. Každá bytová jednotka disponuje balkonem, lodžií, terasou nebo předzahrádkou s&nbsp;výhledem do zeleně či přilehlé vilové čtvrti.
              </SmallText>
              <Tiles>
                <Tile to={APARTMENT_SELECTION}>
                  <span>1+kk</span>
                  <span>od 37&nbsp;m<sup>2</sup></span>
                </Tile>
                <Tile to={APARTMENT_SELECTION}>
                  <span>2+kk</span>
                  <span>od 46&nbsp;m<sup>2</sup></span>
                </Tile>
                <Tile to={APARTMENT_SELECTION}>
                  <span>3+kk</span>
                  <span>od 73&nbsp;m<sup>2</sup></span>
                </Tile>
              </Tiles>
              <CustomBtn to={APARTMENT_SELECTION}>
                Nabídka bytů
              </CustomBtn>
            </div>
            <div>
              <StaticImage src="../../images/img-01.jpg" placeholder="blurred" alt="Foto" layout="fullWidth" />
            </div>
          </Grid>
        </ContentWrapper>
      </Section>
  );
}

export default MoreIndexInfo;